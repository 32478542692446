import Vue from 'vue'
import moment from 'moment'

Vue.filter('datetime', (value) => {
  if (!value) return ''
  return moment(value).format('DD-MM-YYYY HH:mm:ss')
})
Vue.filter('speed', (value) => {
  return (parseFloat(value) * 1.852).toFixed(2) + ' Km/h';
})

Vue.filter('fromNow', (value) => moment(String(value)).fromNow())
