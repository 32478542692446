<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="91.2491mm"
    height="74.4053mm"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 5616 4579"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <!-- Definición del Filtro de Relieve -->
      <filter height="200%" width="200%" y="-50%" x="-50%" id="relieve">
        <!-- Sombra externa -->
        <feGaussianBlur result="blur1" stdDeviation="5" in="offset1" />
        <!-- Sombra interna -->
        <feGaussianBlur result="blur2" stdDeviation="3" in="offset2" />
        <!-- Combinar sombras con el gráfico original -->
      </filter>
      <!-- Definición de Gradiente para Relleno -->
      <!-- Definición de Clase de Estilo -->
      <linearGradient y2="100%" x2="100%" y1="0%" x1="0%" id="gradienteRelieve">
        <stop stop-color="#435052" offset="0%" />
        <stop stop-color="#ffffff" offset="100%" />
      </linearGradient>
    </defs>
    <g id="Capa_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <g id="_2291186954752">
        <path
          class="fil0"
          d="M2807 0c1305,0 2364,849 2364,1897 0,1047 -1059,1896 -2364,1896 -1305,0 -2364,-849 -2364,-1896 0,-1048 1059,-1897 2364,-1897zm-1475 3047c1194,1153 4076,397 3550,-1421 76,1659 -1730,2368 -3550,1421z"
        />
        <path
          class="fil0"
          d="M10 2113c-300,3171 6326,3512 5542,-316 275,3008 -4933,3163 -5542,316z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend();
</script>

<style type="text/css" scoped>
.fil0 {
  fill: url(#gradienteRelieve);
  filter: url(#relieve);
}
</style>
