<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="92.0076mm"
    height="72.4863mm"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 4520 3561"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <!-- Definición del Filtro de Relieve -->
      <filter height="200%" width="200%" y="-50%" x="-50%" id="relieve">
        <!-- Sombra externa -->
        <feGaussianBlur result="blur1" stdDeviation="5" in="offset1" />
        <!-- Sombra interna -->
        <feGaussianBlur result="blur2" stdDeviation="3" in="offset2" />
        <!-- Combinar sombras con el gráfico original -->
      </filter>
      <!-- Definición de Gradiente para Relleno -->
      <!-- Definición de Clase de Estilo -->
      <linearGradient y2="100%" x2="100%" y1="0%" x1="0%" id="gradienteRelieve">
        <stop stop-color="#435052" offset="0%" />
        <stop stop-color="#ffffff" offset="100%" />
      </linearGradient>
    </defs>
    <g id="Capa_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <path
        class="fil0"
        d="M388 2947l0 471c0,49 88,141 136,141l360 0c57,0 144,-74 144,-141l0 -471 -640 0z"
      />
      <path
        class="fil0"
        d="M3495 2949l0 471c0,49 87,141 136,141l359 0c58,0 144,-74 144,-141l0 -471 -639 0z"
      />
      <path
        class="fil0"
        d="M1303 343l5 -240c1,-66 68,-100 131,-101l664 -1 4 341 -804 1zm1117 -342l673 -1c51,0 133,45 134,117l2 224 -809 1 0 -341z"
      />
      <path
        class="fil0"
        d="M499 1407l250 -777c67,-129 158,-221 291,-242l2380 -7c175,6 291,94 358,250l244 765 4 -97c1,-47 42,-82 89,-82l321 -4c38,0 84,36 84,85l0 193c0,43 -43,74 -79,74l-324 5c141,124 223,279 246,465l-5 708c-40,108 -116,164 -222,175l-3702 -3c-180,-13 -234,-66 -266,-212l1 -709c30,-162 108,-305 239,-427l-329 0c-40,0 -79,-24 -79,-74l0 -194c0,-52 34,-85 84,-84l311 3c92,-2 104,66 104,189zm597 784l95 204c25,52 -14,95 -68,89l-485 -51c-37,-4 -54,-30 -66,-79l-43 -189c-13,-54 14,-99 74,-92l420 50c32,4 56,30 73,68zm2330 -2l-95 204c-24,52 14,96 68,90l485 -51c38,-4 55,-30 66,-79l44 -190c12,-54 -14,-99 -75,-91l-420 50c-32,4 -56,30 -73,67zm-1989 182c-12,81 31,138 77,138l1515 0c44,0 90,-138 56,-138l-1648 0zm36 -135l1579 0c-10,-77 -44,-127 -107,-140l-1393 0c-55,35 -76,84 -79,140zm-708 -779l2998 8 -215 -689c-18,-59 -61,-127 -149,-127l-2259 0c-92,0 -147,58 -171,135l-204 673z"
      />
    </g>
  </svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend();
</script>

<style type="text/css" scoped>
.fil0 {
  fill: url(#gradienteRelieve);
  filter: url(#relieve);
}
</style>
