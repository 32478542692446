<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="90.0001mm"
    height="100.615mm"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 4073 4553"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <!-- Definición del Filtro de Relieve -->
      <filter height="200%" width="200%" y="-50%" x="-50%" id="relieve">
        <!-- Sombra externa -->
        <feGaussianBlur result="blur1" stdDeviation="5" in="offset1" />
        <!-- Sombra interna -->
        <feGaussianBlur result="blur2" stdDeviation="3" in="offset2" />
        <!-- Combinar sombras con el gráfico original -->
      </filter>
      <!-- Definición de Gradiente para Relleno -->
      <!-- Definición de Clase de Estilo -->
      <linearGradient y2="100%" x2="100%" y1="0%" x1="0%" id="gradienteRelieve">
        <stop stop-color="#435052" offset="0%" />
        <stop stop-color="#ffffff" offset="100%" />
      </linearGradient>
    </defs>
    <g id="Capa_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <path
        class="fil0"
        d="M0 0l352 46 6 206 2219 6c107,13 159,66 163,151l3 461c-212,-53 -423,-54 -634,-5l-4 -154c-654,99 -1252,274 -1761,532l4 392 -341 62 -7 -1697z"
      />
      <path
        class="fil0"
        d="M1115 3558l-6 79c-131,-73 -252,-159 -328,-302 6,-1032 323,-1785 1001,-2252 448,-256 883,-242 1305,15 805,591 982,1390 986,2249 -64,117 -174,210 -325,280l10 -73c105,-49 193,-120 258,-222 41,-107 -51,-250 -218,-323 -816,-357 -2035,-304 -2761,21 -143,71 -229,168 -184,322 61,97 152,162 262,206z"
      />
      <path
        class="fil0"
        d="M1141 3255c150,1846 2628,1618 2564,-18 -559,-285 -1906,-340 -2564,18zm1289 489c162,0 293,131 293,292 0,162 -131,293 -293,293 -161,0 -292,-131 -292,-293 0,-161 131,-292 292,-292z"
      />
    </g>
  </svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend();
</script>

<style type="text/css" scoped>
.fil0 {
  fill: url(#gradienteRelieve);
  filter: url(#relieve);
}
</style>
