import qs from 'querystring';
import Cookies from 'js-cookie';
import AuthService from '@/util/auth.service';
import router from '@/router';

const defaultState = {
  JSESSIONID: '',
  user: {},
  lastUser: null
};

const getters = {
  getAccessToken: (state) => state.JSESSIONID,
  getUser: (state) => state.user,
};

const actions = {
  async login({ commit }, { username, password, _undefined }) {
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {
      AuthService({
        url: '/session',
        method: 'post',
        data: qs.stringify({
          email: username,
          password,
          undefined: _undefined
        }),
      }).then((data) => {
        commit('SET_ACCESS_TOKEN', Cookies.get('JSESSIONID'));
        commit('SET_LOGIN', { data });
        resolve()
      }).catch(reject);
      // dispatch('fetchProfile');
    })
  },
  logout({ commit }) {
    commit('SET_ACCESS_TOKEN', null);
    // AuthService({
    //   url: '/session',
    //   method: 'get',
    // }).then(() => {
    //   AuthService({
    //     url: '/session',
    //     method: 'delete',
    //   })
    // })
    window.VMA.$emit('SHOW_SNACKBAR', {
      text: 'Logout successfull',
      color: 'success'
    });
    // Cookies.remove('JSESSIONID');
    commit('SET_LOGOUT');
    // router.push('/auth/login');
  },
  // get current login user info
  fetchProfile() {
    /* return AuthService({
      url: '/me',
      method: 'get'
    }).then(({ data }) => {
      commit('SET_LOGIN_PROFILE', data);
    }); */
  },
  updateProfile({ commit }, data) {
    commit('SET_LOGIN', { data, update: true });
  }
};

const mutations = {
  SET_LOGIN(state, { data, update = false }) {
    if (!update && state.lastUser !== data.id) {
      this.dispatch('cameras/clearStore', null, { root: true })
      this.dispatch('alerts/clearStore', null, { root: true })
      this.dispatch('map/clearStore', null, { root: true })
    }
    state.user = data;
  },
  SET_LOGOUT(state) {
    // state.lastUser = state.user ? state.user.id : state.lastUser
    // state.user = {};
    AuthService({
      url: '/session',
      method: 'delete',
    }).then(() => {
      console.log('session deleted');
    }).catch(() => {
      console.log('session not deleted');
    }).finally(() => {
      state.lastUser = state.user ? state.user.id : state.lastUser
      state.user = {};
      Cookies.remove('JSESSIONID');
      router.push('/auth/login');
    });
  },
  SET_ACCESS_TOKEN(state, JSESSIONID) {
    state.JSESSIONID = JSESSIONID;
  },
  SET_LOGIN_PROFILE(state, payload) {
    state.user = payload;
  }
};

export default {
  namespaced: true,
  defaultState,
  getters,
  actions,
  mutations
};
