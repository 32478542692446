<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="78.5881mm"
    height="82.9513mm"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 3337 3522"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <!-- Definición del Filtro de Relieve -->
      <filter height="200%" width="200%" y="-50%" x="-50%" id="relieve">
        <!-- Sombra externa -->
        <feGaussianBlur result="blur1" stdDeviation="5" in="offset1" />
        <!-- Sombra interna -->
        <feGaussianBlur result="blur2" stdDeviation="3" in="offset2" />
        <!-- Combinar sombras con el gráfico original -->
      </filter>
      <!-- Definición de Gradiente para Relleno -->
      <!-- Definición de Clase de Estilo -->
      <linearGradient y2="100%" x2="100%" y1="0%" x1="0%" id="gradienteRelieve">
        <stop stop-color="#435052" offset="0%" />
        <stop stop-color="#ffffff" offset="100%" />
      </linearGradient>
    </defs>
    <g id="Capa_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <path
        class="fil0"
        d="M2639 3154l0 303c0,36 -29,65 -65,65l-218 0c-35,0 -65,-29 -65,-65l0 -303 348 0z"
      />
      <path
        class="fil0"
        d="M3010 3155l0 302c0,36 -29,65 -65,65l-218 0c-36,0 -65,-29 -65,-65l0 -302 348 0z"
      />
      <path
        class="fil0"
        d="M768 121l3 -63c2,-30 30,-58 65,-58l1667 0c34,0 65,32 65,61l0 63 -1800 -3z"
      />
      <path
        class="fil0"
        d="M403 165l2514 -1c103,62 150,189 169,347 56,874 40,1706 -19,2513 -2,78 -23,116 -85,115l-2636 4c-49,3 -68,-33 -84,-113 -53,-805 -70,-1607 -18,-2402 -8,-203 47,-356 159,-463zm2141 2672l433 -5c47,-6 81,-33 95,-90l17 -150c-145,-4 -286,3 -421,24 -91,26 -125,104 -124,221zm-1755 0l-434 -5c-48,-6 -82,-33 -95,-90l-18 -151c146,-4 287,4 422,25 91,25 125,104 125,221zm-420 -2049c3,-49 24,-74 63,-74l2463 -2c44,0 69,33 72,74 33,472 28,906 18,1325 -1,48 -11,70 -38,70l-2537 -5c-35,0 -59,-12 -60,-40 -17,-449 -12,-899 19,-1348z"
      />
      <path
        class="fil1"
        d="M266 2199l14 357c136,0 268,6 392,25 120,24 132,92 141,210l11 159c4,59 64,87 140,84l1449 -7c59,-2 99,-30 102,-112l7 -133c-1,-97 30,-171 114,-198 131,-24 271,-27 413,-24l18 -364 -2801 3z"
      />
      <path
        class="fil0"
        d="M3094 643l145 -1c17,-1 30,5 32,25l0 46c0,14 -9,20 -26,20l0 25 47 0c22,-2 35,13 41,42l4 379c-2,20 -14,33 -36,39l-139 0c-19,-4 -33,-18 -41,-40l3 -378c3,-20 10,-42 34,-42l50 0 -1 -26 -108 0 -5 -89z"
      />
      <path
        class="fil0"
        d="M243 645l-146 -2c-16,-1 -29,5 -31,26l0 45c0,14 9,21 26,21l0 24 -48 0c-21,-2 -34,13 -40,42l-4 379c2,20 14,33 36,39l139 0c19,-4 33,-18 41,-40l-4 -378c-2,-20 -9,-42 -34,-42l-49 0 1 -26 108 0 5 -88z"
      />
      <path
        class="fil0"
        d="M906 2627l16 162c2,22 17,33 40,33l1397 -2c29,0 52,-9 54,-33l16 -153c2,-22 -13,-47 -39,-47l-1443 1c-24,0 -43,17 -41,39z"
      />
      <path
        class="fil0"
        d="M670 3154l0 303c0,36 -29,65 -64,65l-218 0c-36,0 -65,-29 -65,-65l-1 -303 348 0z"
      />
      <path
        class="fil0"
        d="M1041 3155l0 302c0,36 -29,65 -64,65l-219 0c-35,0 -64,-29 -64,-65l0 -302 347 0z"
      />
    </g>
  </svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend();
</script>

<style type="text/css" scoped>
.fil0 {
  fill: url(#gradienteRelieve);
  filter: url(#relieve);
}
.fil1 {
  fill: #e6e7e8;
}
</style>
