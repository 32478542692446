<template>
  <v-navigation-drawer
    app
    class="app-drawer"
    :mini-variant.sync="mini"
    v-model="drawer"
    :dark="true"
    permanent
  >
    <v-list-item class="px-2">
      <!-- <v-list-item-avatar>
        <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
      </v-list-item-avatar>

      <v-list-item-title>John Leider</v-list-item-title> -->

      <v-btn
        icon
        @click.stop="mini = !mini"
      >
        <v-icon v-html="mini ? 'mdi-chevron-right' : 'mdi-chevron-left'"></v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense class="pa-0">
        <template v-for="(item, key) in computeMenu">
          <!-- <template v-if="item.children && item.children.length > 0">
            <v-list-group :key="key" no-action :to="item.path">
              <template v-slot:prependIcon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" v-text="item.meta.icon" />
                  </template>
                  <span>
                    {{ $vuetify.lang.t('$vuetify.menu.' + item.meta.title) }}
                  </span>
                </v-tooltip>
              </template>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="$vuetify.lang.t('$vuetify.menu.' + item.meta.title)"
                  />
                </v-list-item-content>
              </template>
              <v-list-item
                :class="drawerWidth === 64 ? 'pl-4' : ''"
                v-for="subItem in item.children"
                :key="subItem.name"
                :to="subItem.path"
                v-show="!subItem.meta.hiddenInMenu"
              >
                <template v-if="drawerWidth === 64">
                  <v-list-item-icon>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          v-text="subItem.meta.icon"
                        />
                      </template>
                      <span>{{
                        $vuetify.lang.t('$vuetify.menu.' + subItem.meta.title)
                      }}</span>
                    </v-tooltip>
                  </v-list-item-icon>
                </template>
              </v-list-item>
            </v-list-group>
          </template> -->
          <template>
            <v-list-item
              :key="key"
              :to="item.path"
              v-if="requireAdmin(item)"
            >
              <v-list-item-icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" v-text="item.meta.icon" />
                  </template>
                  <span>{{
                    $vuetify.lang.t('$vuetify.menu.' + item.meta.title)
                  }}</span>
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="$vuetify.lang.t('$vuetify.menu.' + item.meta.title)"
                />
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </v-list>
  </v-navigation-drawer>
</template>

<script>
import { protectedRoute as routes } from '@/router/routes';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      drawer: true,
      items: [
        { title: 'Home', icon: 'mdi-home-city' },
        { title: 'My Account', icon: 'mdi-account' },
        { title: 'Users', icon: 'mdi-account-group-outline' },
      ],
      mini: false,
    }
  },
  methods: {
    requireAdmin(item) {
      if (item.meta.admin) {
        return this.getUser.administrator;
      }
      return true;
    },
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    computeMenu() {
      return [{
        path: '/home',
        name: 'home',
        meta: {
          title: 'home',
          group: 'apps',
          icon: '$home',
          admin: true
        },
        component: () => import('@/views/Home.vue'),
      },
      ...routes[1].children
      ];
    },
  },
}
</script>

<style>

</style>
